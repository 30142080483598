/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

const WEEKDAYS_OF_WEEK = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
};

const getInvalidDays = (dayToday, isRsvp, isAfterProcessingTime) => {
  if (dayToday <= 0 || dayToday > 5) return [];

  const invalidDays = [{ day: dayToday, message: 'today' }];

  if (isRsvp || (!isRsvp && isAfterProcessingTime)) {
    if (dayToday === 5) {
      // We don't want to send messages on weekends so we skip to Monday
      invalidDays.push({ day: 1, message: 'next Monday' });
    } else {
      invalidDays.push({ day: dayToday + 1, message: 'this week' });
    }
  }

  if (isRsvp && isAfterProcessingTime) {
    switch (dayToday) {
      case 5:
        invalidDays.push({ day: 2, message: 'next week' });
        break;
      case 4:
        invalidDays.push({ day: 1, message: 'next week' });
        break;
      default: {
        invalidDays.push({ day: dayToday + 2, message: 'this week' });
      }
    }
  }

  return invalidDays;
};

const getWarningMessageData = ({ isRsvp = false, selectedDays = [] }) => {
  const systemTimezone = 'Europe/Berlin';
  const timezone = moment.tz.guess();
  const userTimeNow = moment().tz(timezone);

  const systemProcessingTime = moment()
    .tz(systemTimezone)
    .set({
      hour: isRsvp ? 23 : 22,
      minute: isRsvp ? 0 : 30,
      second: 0,
    });

  const userProcessingTime = moment(systemProcessingTime)
    .tz(timezone)
    .set({ month: userTimeNow.month(), date: userTimeNow.date(), year: userTimeNow.year() });

  const isAfterProcessingTime = userTimeNow.isAfter(userProcessingTime);
  const processingTimeOfDay = userProcessingTime.format('h:mm A');

  let day = userTimeNow.day();
  // Treat weekends as Friday
  if ([6, 7].includes(day)) {
    day = 5;
  }

  const invalidDays = getInvalidDays(day, isRsvp, isAfterProcessingTime);

  if (invalidDays.length === 0) {
    return {
      processingTime: processingTimeOfDay,
      invalidDays: [],
    };
  }

  // Only show warning messages for days that are selected
  const filteredDays = invalidDays.filter(({ day: d }) => selectedDays.includes(d));

  return {
    processingTime: processingTimeOfDay,
    invalidDays: filteredDays.map(({ day: d, message }) => `${WEEKDAYS_OF_WEEK[d]} (${message})`),
  };
};

const getDefaultInvitationMessage = (category) => {
  if (category.toLowerCase() === 'coffee') {
    return '☕️💻 Hey _NAME_! Would you be up for _ROULETTE_ coffee with your coworkers this _DAY_?';
  }
  return '🍴🌮 Hey _NAME_! This _DAY_ is _ROULETTE_ lunch day. Are you joining?';
};

const getDefaultGroupMessage = (category) => {
  if (category.toLowerCase() === 'coffee') {
    return '🌈 Good news everyone! You are matched for _ROULETTE_ coffee chat TODAY! You need to align on a time that fits all + tea/coffee + good mood ✨ + a topic like a recent highlight. So... Let me start then... 👀 _TOPIC_';
  }

  return '🙌 WOOT! Good news everyone! You are going to _ROULETTE_ together TODAY!';
};

export {
  getDefaultInvitationMessage,
  getDefaultGroupMessage,
  getWarningMessageData,
  WEEKDAYS_OF_WEEK,
};
