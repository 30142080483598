import * as Sentry from '@sentry/vue';
import apiClient from '@/store/helpers/api-client';

const SET_LOG_IN_LOADING = 'SET_LOG_IN_LOADING';
const REQUEST_LOG_IN_CHECK_SUCCESS = 'REQUEST_LOG_IN_CHECK_SUCCESS';

const state = {
  isLoggedIn: false,
  tier: 'BASIC',
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [REQUEST_LOG_IN_CHECK_SUCCESS](state, data) { // eslint-disable-line
    state.isLoggedIn = true;
    state.tier = data.tier || 'BASIC';
  },
  [SET_LOG_IN_LOADING](state, isLoading) { // eslint-disable-line
    state.loading = isLoading;
  },
};

const actions = {
  async authenticate({ commit }) {
    if (state.isLoggedIn) return;
    commit(SET_LOG_IN_LOADING, true);
    try {
      const { data } = await apiClient.get('/me');
      Sentry.setUser(data);
      commit(REQUEST_LOG_IN_CHECK_SUCCESS, data);
    } finally {
      commit(SET_LOG_IN_LOADING, false);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
