import Vue from 'vue';
import Router from 'vue-router';
import WithSidebar from '@/layouts/with-sidebar.vue';
import WithoutSidebar from '@/layouts/without-sidebar.vue';

import RouletteEdit from '@/views/roulette-edit.vue';
import RouletteCreate from '@/views/roulette-create.vue';
import Login from '@/views/login.vue';
import Home from '@/views/home.vue';
import Account from '@/views/account.vue';
import Success from '@/views/success.vue';
import Report from '@/views/report.vue';
import store from './store';

Vue.use(Router);

const ensureAuthentication = async (to, from, next) => {
  if (to.path === '/login') return next();

  try {
    await store.dispatch('authenticate');
    return next();
  } catch (e) {
    return next('/login');
  }
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { layout: WithSidebar },
    },
    {
      path: '/create',
      name: 'create',
      component: RouletteCreate,
      meta: { layout: WithSidebar },
    },
    {
      path: '/edit/:id',
      name: 'edit',
      props: true,
      component: RouletteEdit,
      meta: { layout: WithSidebar },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { layout: WithoutSidebar },
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: { layout: WithoutSidebar },
    },
    {
      path: '/success',
      name: 'success',
      component: Success,
      meta: { layout: WithoutSidebar },
    },
    {
      path: '/report',
      name: 'report',
      component: Report,
      meta: { layout: WithoutSidebar },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(ensureAuthentication);

export default router;
