import apiClient from '@/store/helpers/api-client';

const SET_CHECKOUT_LOADING = 'SET_CHECKOUT_LOADING';

const state = {
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [SET_CHECKOUT_LOADING](state, isLoading) { // eslint-disable-line
    state.loading = isLoading;
  },
};

const actions = {
  async checkout({ commit }, priceId) {
    commit(SET_CHECKOUT_LOADING, true);
    const { data: checkoutId } = await apiClient.post('/checkout-session', { priceId });
    const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY); // eslint-disable-line
    stripe.redirectToCheckout({
      sessionId: checkoutId,
    });
    commit(SET_CHECKOUT_LOADING, false);
  },
};

export default {
  state,
  mutations,
  actions,
};
