<script>
import { mapState, mapActions } from 'vuex';

import moment from 'moment-timezone';

const { months } = require('@/fixture');

const currentMonth = (new Date()).getMonth();
const currentYear = (new Date()).getFullYear();

export default {
  name: 'Report',
  data() {
    return {
      year: currentYear,
      years: [2020, 2021, 2022],
      month: currentMonth,
      months,
      fields: [{
        key: 'meetingDay',
        label: '📅 Date',
        formatter: 'formatMeetingDate',
        class: 'short',
      }, {
        key: 'acceptedUsers',
        label: '👍 Replied "Yes"',
        formatter: 'formatListOfUsers',
        class: 'long',
      }, {
        key: 'rejectedUsers',
        label: '💔 Replied "No"',
        formatter: 'formatListOfUsers',
        class: 'long',
      }, {
        key: 'passiveUsers',
        label: '👀 Didn\'t respond',
        formatter: 'formatListOfUsers',
        class: 'long',
      }],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.report.loading,
      report: (state) => state.report.report,
      roulettes: (state) => state.roulette.roulettes,
    }),
  },
  created() {
    this.fetchRoulettes();
    this.refresh();
    // this.fetchStatisticsForRoulette({
    //   month: this.month,
    //   year: this.year,
    //   id: '60a04fb5d57199394d35e3d2',
    // });
  },
  methods: {
    ...mapActions(['fetchReport', 'fetchRoulettes', 'fetchStatisticsForRoulette']),
    refresh() {
      this.fetchReport({ month: this.month, year: this.year });
    },
    formatListOfUsers(value) {
      return value.map((u) => `@${u.name}`).join(', ');
    },
    formatMeetingDate(value) {
      return moment(value).format('MMM D');
    },
  },
};
</script>

<template>
  <div
    v-if="!loading"
    class="wrapper"
  >
    <div class="sidebar p-4">
      <b-form-select
        v-model="year"
        class="mb-3"
        :options="years"
        required
        @change="refresh"
      />
      <b-form-select
        v-model="month"
        class="mb-3"
        :options="months"
        required
        @change="refresh"
      />
    </div>
    <div class="p-4 w-100">
      <small class="text-muted">MONTHLY STATISTICS REPORT</small>
      <h4 class="my-3">
        {{ months[month].text }} {{ year }}
      </h4>
      <div class="d-flex justify-content-between text-center mt-5">
        <div
          class="info-box p-2"
          show
          variant="light"
        >
          <small class="text-muted">MATCHED GROUPS</small>
          <h1 class="m-3">
            {{ report.totalGroups }}
          </h1>
        </div>
        <div
          class="info-box p-2"
          show
          variant="light"
        >
          <small class="text-muted">ACCEPTANCE RATIO</small>
          <h1 class="m-3">
            {{ (report.acceptanceRatio * 100).toFixed() }}%
          </h1>
        </div>
        <div
          class="info-box p-2"
          show
          variant="light"
        >
          <small class="text-muted">TOTAL PARTICIPATION</small>
          <h1 class="m-3">
            {{ report.totalInvitationsAccepted }}
          </h1>
        </div>
      </div>
      <hr class="my-5">
      <div
        v-for="roulette in roulettes"
        :key="roulette.id"
        class="mt-4"
      >
        <div class="info-box p-3">
          <h4>{{ roulette.name }}</h4>
          <b-table
            :fields="fields"
            class="rounds-table mt-4"
            show-empty
            :empty-text="`No rounds happened in ${months[month].text} for ${roulette.name}`"
            :items="report.roulettes[roulette.id]"
          >
            <template #empty="scope">
              <p class="text-center">
                {{ scope.emptyText }}
              </p>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  width: 250px;
  flex: 0 0 250px;
}

.wrapper {
  display: flex;
  align-items: stretch;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.info-box {
  background: #F7F7F5;
  border: 1px solid #DEDEDE;
  border-radius: .75rem;
  margin: 0 20px;
  width: 100%;

  &:first-child, &:last-child {
    margin: 0;
  }
}
</style>

<style lang="scss">
table.rounds-table {
  th.short {
    min-width: 75px;
  }
  th.long {
    min-width: 134px;
  }
}
</style>
