<script>
const authUrl = `${process.env.VUE_APP_API_URL}/auth`;
const loginUrl = `${process.env.VUE_APP_API_URL}/login`;
export default {
  name: 'Login',
  data() {
    return {
      authUrl,
      loginUrl,
    };
  },
};
/* eslint-disable max-len */
</script>

<template>
  <div class="login-wrapper">
    <a :href="authUrl">
      <img
        id="#add-to-slack"
        alt="Add to Slack"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      >
    </a>
    <a :href="loginUrl">
      <img
        class="slack"
        alt="Sign in with Slack"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      >
    </a>
  </div>
</template>

<style lang="scss" scoped>
.login-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
}
#add-to-slack {
  height: 40px;
  width: 139px;
}
#sign-in-with-slack {
  height: 40px;
  width: 172px;
}
</style>
