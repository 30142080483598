import apiClient from '@/store/helpers/api-client';

const SET_REPORT_LOADING = 'SET_REPORT_LOADING';
const REQUEST_GET_REPORT_SUCCESS = 'REQUEST_GET_REPORT_SUCCESS';
const REQUEST_GET_REPORT_ERROR = 'REQUEST_GET_REPORT_ERROR';

const state = {
  report: {},
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [SET_REPORT_LOADING](state, isLoading) { // eslint-disable-line
    state.loading = isLoading;
  },
  [REQUEST_GET_REPORT_SUCCESS](state, data) { // eslint-disable-line
    state.report = data;
  },
  [REQUEST_GET_REPORT_ERROR](state, data) { // eslint-disable-line
    state.error = true;
    state.errorMessage = data;
  },
};

const actions = {
  async fetchReport({ commit }, { month, year }) {
    commit(SET_REPORT_LOADING, true);
    await apiClient.get(`/report?month=${month}&year=${year}`)
      .then(({ data }) => {
        commit(REQUEST_GET_REPORT_SUCCESS, data);
        commit(SET_REPORT_LOADING, false);
      })
      .catch((response) => {
        commit(REQUEST_GET_REPORT_ERROR, response.data);
        commit(SET_REPORT_LOADING, false);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
