<script>
import { mapState, mapActions } from 'vuex';

const { countryCodes } = require('@/fixture');

export default {
  name: 'Account',
  data() {
    return {
      countryCodes,
      taxIdData: {
        type: '',
        value: '',
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account.account,
      loading: (state) => state.account.loading,
    }),
    billingPortalLink() {
      return `${process.env.VUE_APP_API_URL}/admin/billing-portal`;
    },
  },
  created() {
    this.fetchAccount();
  },
  methods: {
    ...mapActions(['saveAccount', 'fetchAccount']),
    onSubmit() {
      this.saveAccount(this.account);
    },
  },
};
/* eslint-disable max-len */
</script>

<template>
  <div class="wrapper">
    <div class="sidebar p-4">
      <b-nav
        vertical
        pills
      >
        <b-nav-item active>
          Account
        </b-nav-item>
        <b-nav-item :href="billingPortalLink">
          Billing
        </b-nav-item>
        <b-nav-item disabled>
          Reporting (Soon)
        </b-nav-item>
      </b-nav>
    </div>
    <div class="p-4 w-100">
      <b-form @submit.stop.prevent="onSubmit">
        <h3>Account Details</h3>
        <b-form-group
          label="Company Name"
          label-for="name"
          class="w-75"
        >
          <b-form-input
            id="name"
            v-model="account.name"
            placeholder="Jane Smith"
            type="text"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          label="Contact Email"
          label-for="email"
          class="w-75"
        >
          <b-form-input
            id="email"
            v-model="account.email"
            placeholder="jane@example.com"
            type="email"
            autocomplete="off"
          />
        </b-form-group>
        <h3 class="mt-5">
          Adress Details
        </h3>
        <b-form-group
          label="Country"
          label-for="country"
          class="w-25"
        >
          <b-form-select
            v-model="account.address.country"
            :options="countryCodes"
          />
        </b-form-group>
        <b-form-group
          label="City"
          label-for="city"
          class="w-75"
        >
          <b-form-input
            id="city"
            v-model="account.address.city"
            type="text"
            autocomplete="off"
          />
          <b-form-text>city, district, suburb, town, or village</b-form-text>
        </b-form-group>
        <b-form-group
          label="Address Line 1"
          label-for="line1"
          class="w-75"
        >
          <b-form-input
            id="line1"
            v-model="account.address.line1"
            type="text"
            autocomplete="off"
            required
          />
          <b-form-text>e.g., street, PO Box, or company name.</b-form-text>
        </b-form-group>
        <b-form-group
          label="Address Line 2"
          label-for="line2"
          class="w-75"
        >
          <b-form-input
            id="line2"
            v-model="account.address.line2"
            type="text"
            autocomplete="off"
          />
          <b-form-text>e.g., apartment, suite, unit, or building</b-form-text>
        </b-form-group>
        <b-form-row
          class="w-75"
          no-gutters="true"
        >
          <b-col>
            <b-form-group
              label="Postal Code"
              label-for="postalCode"
              class="w-100"
            >
              <b-form-input
                id="postalCode"
                v-model="account.address.postalCode"
                inline
                type="text"
                autocomplete="off"
              />
              <b-form-text>ZIP or postal code</b-form-text>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="State"
              label-for="state"
              class="w-100"
            >
              <b-form-input
                id="state"
                v-model="account.address.state"
                inline
                type="text"
                autocomplete="off"
              />
              <b-form-text>state, county, province, or region</b-form-text>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group class="mt-5">
          <b-button
            variant="primary"
            class="px-4"
            type="submit"
          >
            Save
          </b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  width: 250px;
  flex: 0 0 250px;
}

.wrapper {
  display: flex;
  align-items: stretch;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
</style>
