import apiClient from '@/store/helpers/api-client';

const SET_USERS_LOADING = 'SET_USERS_LOADING';
const REQUEST_GET_USERS_SUCCESS = 'REQUEST_GET_USERS_SUCCESS';

const state = {
  users: [],
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [SET_USERS_LOADING](state, isLoading) { // eslint-disable-line
    state.loading = isLoading;
  },
  [REQUEST_GET_USERS_SUCCESS](state, data) { // eslint-disable-line
    state.users = data;
  },
};

const actions = {
  async fetchUsersForRoulette({ commit }, rouletteId) {
    commit(SET_USERS_LOADING, true);
    const { data } = await apiClient.get(`/roulette/${rouletteId}/users`);
    commit(REQUEST_GET_USERS_SUCCESS, data);
    commit(SET_USERS_LOADING, false);
  },
};

export default {
  state,
  mutations,
  actions,
};
