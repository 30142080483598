<script>
export default {
  name: 'Success',
  data() {
    return {
      dismissCountDown: 5,
      showDismissibleAlert: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$router.replace({ name: 'home' });
    }, 4000);
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>

<template>
  <div class="text-center m-5">
    <h1>🙌 Success!</h1>
    <h4 class="mb-5">
      You have supercharged your roulettes.
    </h4>
    <b-alert
      class="m-auto w-50"
      show="5"
      @dismiss-count-down="countDownChanged"
    >
      Redirecting to dashboard in {{ dismissCountDown }}
    </b-alert>
  </div>
</template>
