import Vue from 'vue';
import Vuex from 'vuex';

import roulette from './modules/roulette';
import channel from './modules/channel';
import login from './modules/login';
import account from './modules/account';
import checkout from './modules/checkout';
import report from './modules/report';
import user from './modules/user';

Vue.use(Vuex);

const store = {
  modules: {
    roulette,
    channel,
    login,
    account,
    checkout,
    report,
    user,
  },
};

export default new Vuex.Store(store);
