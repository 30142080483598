<script>
export default {
  name: 'Home',
};
</script>

<template>
  <div class="p-4">
    There are no roulettes. Use the "Create New" button on the left to create your first roulette.
  </div>
</template>
