import apiClient from '@/store/helpers/api-client';

const SET_CHANNELS_LOADING = 'SET_CHANNELS_LOADING';
const REQUEST_GET_CHANNELS_SUCCESS = 'REQUEST_GET_CHANNELS_SUCCESS';

const state = {
  channels: [],
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [SET_CHANNELS_LOADING](state, isLoading) { // eslint-disable-line
    state.loading = isLoading;
  },
  [REQUEST_GET_CHANNELS_SUCCESS](state, data) { // eslint-disable-line
    state.channels = data;
  },
};

const actions = {
  async fetchChannels({ commit }) {
    commit(SET_CHANNELS_LOADING, true);
    const { data } = await apiClient.get('/channels');
    commit(REQUEST_GET_CHANNELS_SUCCESS, data);
    commit(SET_CHANNELS_LOADING, false);
  },
};

export default {
  state,
  mutations,
  actions,
};
