import apiClient from '@/store/helpers/api-client';

const SET_ACCOUNT_LOADING = 'SET_ACCOUNT_LOADING';
const REQUEST_GET_ACCOUNT_SUCCESS = 'REQUEST_GET_ACCOUNT_SUCCESS';
const REQUEST_SAVE_ACCOUNT_SUCCESS = 'REQUEST_SAVE_ACCOUNT_SUCCESS';
const REQUEST_SAVE_ACCOUNT_ERROR = 'REQUEST_SAVE_ACCOUNT_ERROR';

const state = {
  account: {
    address: {},
  },
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [SET_ACCOUNT_LOADING](state, isLoading) { // eslint-disable-line
    state.loading = isLoading;
  },
  [REQUEST_GET_ACCOUNT_SUCCESS](state, data) { // eslint-disable-line
    state.account = data || { address: { } };
  },
  [REQUEST_SAVE_ACCOUNT_SUCCESS](state, data) { // eslint-disable-line
    state.account = data;
  },
};

const actions = {
  async fetchAccount({ commit }) {
    commit(SET_ACCOUNT_LOADING, true);
    const { data } = await apiClient.get('/account/billing');
    commit(REQUEST_GET_ACCOUNT_SUCCESS, data);
    commit(SET_ACCOUNT_LOADING, false);
  },
  async saveAccount({ commit }, account) {
    commit(SET_ACCOUNT_LOADING, true);
    await apiClient.put('/account/billing', account)
      .then((response) => {
        commit(REQUEST_SAVE_ACCOUNT_SUCCESS, response.data);
        commit(SET_ACCOUNT_LOADING, false);
      })
      .catch(({ response }) => {
        commit(REQUEST_SAVE_ACCOUNT_ERROR, response.data);
        commit(SET_ACCOUNT_LOADING, false);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
