<script>
import Navbar from '@/components/navbar.vue';

export default {
  components: {
    Navbar,
  },
};
</script>
<template>
  <div id="app">
    <navbar />
    <component :is="$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
</template>

<style lang="scss">
@import './style/index';

</style>
