import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import {
  BNavbar,
  BNavbarNav,
  BForm,
  BNavItem,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BBadge,
  BButtonClose,
  BFormCheckboxGroup,
  BNav,
  BNavbarBrand,
  BFormText,
  BFormInput,
  BFormSelect,
  ModalPlugin,
  BCol,
  BFormRow,
  BButtonGroup,
  BAlert,
  BTable,
  ToastPlugin,
  BFormTextarea,
  BTooltip,
  BIconQuestionCircleFill,
} from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

const isDev = process.env.NODE_ENV === 'development';

Sentry.init({
  dsn: 'https://df7db432dded4242a5e676c9647ecec1@o389375.ingest.sentry.io/5227549',
  Vue,
  logErrors: isDev,
  environment: process.env.NODE_ENV,
  enabled: !isDev,
  debug: isDev,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true,
  },
});

Vue.config.productionTip = isDev;
Vue.component('BButton', BButton);
Vue.component('BButtonGroup', BButtonGroup);
Vue.component('BButtonClose', BButtonClose);
Vue.component('BNav', BNav);
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BNavItem', BNavItem);
Vue.component('BBadge', BBadge);
Vue.component('BCol', BCol);
Vue.component('BAlert', BAlert);
Vue.component('BTable', BTable);
Vue.component('BFormRow', BFormRow);
Vue.component('BForm', BForm);
Vue.component('BFormText', BFormText);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.component('Multiselect', Multiselect);
Vue.component('BTooltip', BTooltip);
Vue.component('BIconQuestionCircleFill', BIconQuestionCircleFill);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
