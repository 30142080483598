<script>
import { mapActions } from 'vuex';

const { PREMIUM_PLANS } = require('@/fixture');

export default {
  name: 'Upgrade',
  data() {
    return {
      fields: [{
        key: 'name',
        label: 'PLAN',
      }, {
        key: 'maxUser',
        label: 'MAX USER',
      }, {
        key: 'amount',
        label: 'MONTHLY PRICE',
      }, {
        key: 'action',
        label: '',
      }],
      interval: 'monthly',
      rangeIndex: 1,
      rangeValues: Object.values(PREMIUM_PLANS).map((e) => e.maxUser),
      PREMIUM_PLANS: Object.values(PREMIUM_PLANS),
    };
  },
  methods: {
    ...mapActions(['checkout']),
    setPlan(priceId) {
      this.checkout(priceId);
    },
    rowStyling(item, type) {
      if (!item || type != 'row') return '';
      if (item.maxUser == this.rangeValues[this.rangeIndex]) return 'table-warning';
      return '';
    },
  },
};
</script>

<template>
  <b-modal
    id="pricing-modal"
    centered
    no-stacking
    size="lg"
    hide-footer
    body-class="pt-0 px-5"
    content-class="p-2"
    scrollable
    header-class="border-0"
  >
    <div class="interval-select text-center">
      <b-button-group>
        <b-button
          variant="outline-primary"
          :pressed="interval == 'monthly'"
          @click="interval = 'monthly'"
        >
          Monthly
        </b-button>
        <b-button
          variant="outline-primary"
          :pressed="interval == 'yearly'"
          @click="interval = 'yearly'"
        >
          Yearly
        </b-button>
      </b-button-group>
    </div>

    <p class="text-center mt-4">
      I want to create roulettes for up to {{ rangeValues[rangeIndex] }} users.
    </p>
    <b-form-input
      v-model="rangeIndex"
      type="range"
      :max="rangeValues.length - 1"
    />
    <b-table
      borderless
      :items="PREMIUM_PLANS"
      :fields="fields"
      :tbody-tr-class="rowStyling"
    >
      <template #cell(action)="row">
        <b-button
          v-if="row.item.id"
          variant="primary"
          size="sm"
          @click="setPlan(row.item.id[interval])"
        >
          Upgrade
        </b-button>
      </template>
      <template #cell(amount)="row">
        {{ row.item.priceFormatted[interval] }}
      </template>
    </b-table>
    <p class="text-center mt-4">
      For more users, contact us at support@lunchroulette.co
    </p>
  </b-modal>
</template>

<style lang="scss" scoped>
.interval-select {
  button {
    width: 100px;
  }
}
</style>
