<script>
import { mapState } from 'vuex';

export default {
  name: 'Navbar',
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.login.isLoggedIn,
      loading: (state) => state.login.loading,
      tier: (state) => state.login.tier,
    }),
    billingPortalLink() {
      return `${process.env.VUE_APP_API_URL}/admin/billing-portal`;
    },
  },
  methods: {
    logout() {
      return window.location.replace(`${process.env.VUE_APP_API_URL}/admin/logout`);
    },
    payment() {
      return window.location.replace(`${process.env.VUE_APP_API_URL}/admin/billing`);
    },
  },
};
</script>

<template>
  <b-navbar class="sticky-top bg-white mb-4">
    <b-navbar-nav>
      <b-nav-item
        href="/"
        :active="['edit', 'create'].includes($route.name)"
      >
        Dashboard
      </b-nav-item>
      <b-nav-item
        v-if="isLoggedIn && tier !='BASIC'"
        :href="billingPortalLink"
      >
        Account
      </b-nav-item>
      <b-nav-item
        v-if="isLoggedIn"
        href="/report"
        :active="$route.name == 'report'"
      >
        Reporting
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      v-if="isLoggedIn"
      class="ml-auto"
    >
      <b-nav-item @click="logout">
        Log out
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid #ced4da;
  height: 70px;
}
</style>
