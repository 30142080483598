/* eslint-disable */
import apiClient from '@/store/helpers/api-client';

import {
  getDefaultGroupMessage,
  getDefaultInvitationMessage,
} from '../../helpers/roulette-form-messages.js';

const SET_ROULETTE_LOADING = 'SET_ROULETTE_LOADING';
const SET_ROULETTES_LOADING = 'SET_ROULETTES_LOADING';
const SET_SAVE_ROULETTE_LOADING = 'SET_SAVE_ROULETTE_LOADING';
const SET_DELETE_ROULETTE_LOADING = 'SET_DELETE_ROULETTE_LOADING';
const REQUEST_GET_ROULETTE_SUCCESS = 'REQUEST_GET_ROULETTE_SUCCESS';
const REQUEST_GET_ROULETTES_SUCCESS = 'REQUEST_GET_ROULETTES_SUCCESS';
const REQUEST_SAVE_ROULETTE_SUCCESS = 'REQUEST_SAVE_ROULETTE_SUCCESS';
const REQUEST_SAVE_ROULETTE_ERROR = 'REQUEST_SAVE_ROULETTE_ERROR';
const REQUEST_DELETE_ROULETTE_SUCCESS = 'REQUEST_DELETE_ROULETTE_SUCCESS';
const REQUEST_DELETE_ROULETTE_ERROR = 'REQUEST_DELETE_ROULETTE_ERROR';

const state = {
  roulette: {},
  originalRoulette: {},
  roulettes: [],
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

const mutations = {
  [SET_ROULETTE_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [SET_ROULETTES_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [SET_SAVE_ROULETTE_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [REQUEST_GET_ROULETTE_SUCCESS](state, data) {
    const groupMessageToUse = data.groupMessage || getDefaultGroupMessage(data.category);
    const inviteMessageToUse = data.invitationMessage || getDefaultInvitationMessage(data.category);

    state.roulette = {
      ...data,
      invitationMessage: inviteMessageToUse,
      groupMessage: groupMessageToUse,
      addRandomMessage: groupMessageToUse.includes('_TOPIC_'),
    };
    state.originalRoulette = { ...state.roulette };
  },
  [REQUEST_GET_ROULETTES_SUCCESS](state, data) {
    state.roulettes = data;
  },
  [REQUEST_SAVE_ROULETTE_SUCCESS](state, data) {
    state.roulette = data;
  },
  [REQUEST_SAVE_ROULETTE_ERROR](state, data) {
    state.roulette = data;
  },
  [REQUEST_DELETE_ROULETTE_SUCCESS](state, data) {
    state.roulette = data;
  },
  [REQUEST_DELETE_ROULETTE_ERROR](state, data) {
    state.roulette = data;
  },
};

const actions = {
  async fetchRoulette({ commit }, id) {
    commit(SET_ROULETTE_LOADING, true);
    const { data } = await apiClient.get(`/roulette/${id}`);
    commit(REQUEST_GET_ROULETTE_SUCCESS, data);
    commit(SET_ROULETTE_LOADING, false);
  },
  async fetchRoulettes({ commit }) {
    commit(SET_ROULETTES_LOADING, true);
    const { data } = await apiClient.get('/roulettes');
    commit(REQUEST_GET_ROULETTES_SUCCESS, data);
    commit(SET_ROULETTES_LOADING, false);
  },
  async createRoulette({ commit }, data) {
    commit(SET_SAVE_ROULETTE_LOADING, true);
    await apiClient
      .post('roulette/', data)
      .then((response) => {
        commit(REQUEST_SAVE_ROULETTE_SUCCESS, response.data);
        commit(SET_SAVE_ROULETTE_LOADING, false);
      })
      .catch(({ response }) => {
        commit(REQUEST_SAVE_ROULETTE_ERROR, response.data);
        commit(SET_SAVE_ROULETTE_LOADING, false);
      });
  },
  async saveRoulette({ commit }, data) {
    commit(SET_SAVE_ROULETTE_LOADING, true);
    await apiClient
      .put(`roulette/${data.id}`, data)
      .then((response) => {
        commit(REQUEST_SAVE_ROULETTE_SUCCESS, response.data);
        commit(SET_SAVE_ROULETTE_LOADING, false);
      })
      .catch(({ response }) => {
        commit(REQUEST_SAVE_ROULETTE_ERROR, response.data);
        commit(SET_SAVE_ROULETTE_LOADING, false);
      });
  },
  async deleteRoulette({ commit }, id) {
    commit(SET_DELETE_ROULETTE_LOADING, true);
    await apiClient
      .delete(`roulette/${id}`)
      .then((response) => {
        commit(REQUEST_DELETE_ROULETTE_SUCCESS, response.data);
        commit(SET_DELETE_ROULETTE_LOADING, false);
      })
      .catch(({ response }) => {
        commit(REQUEST_DELETE_ROULETTE_ERROR, response.data);
        commit(SET_DELETE_ROULETTE_LOADING, false);
      });
  },
  async optOutFromRoulette({ commit }, { rouletteId, userId }) {
    commit(SET_SAVE_ROULETTE_LOADING, true);
    await apiClient
      .delete(`roulette/${rouletteId}/user/${userId}`)
      .then((response) => {
        commit(REQUEST_SAVE_ROULETTE_SUCCESS, response.data);
        commit(SET_SAVE_ROULETTE_LOADING, false);
      })
      .catch(({ response }) => {
        commit(REQUEST_SAVE_ROULETTE_ERROR, response.data);
        commit(SET_SAVE_ROULETTE_LOADING, false);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
