<script>
export default {

};
</script>

<template>
  <div>
    <slot />
  </div>
</template>
