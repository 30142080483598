<script>
import { mapState } from 'vuex';
import Upgrade from '@/components/upgrade.vue';

export default {
  name: 'Sidebar',
  components: {
    Upgrade,
  },
  computed: {
    ...mapState({
      roulettes: (state) => state.roulette.roulettes,
      tier: (state) => state.login.tier,
    }),
  },
  methods: {
    onUpgrade() {
      this.$bvModal.show('pricing-modal');
    },
  },
};
</script>
<template>
  <div
    id="sidebar"
    class="p-4"
  >
    <b-alert :show="tier == 'BASIC'">
      <span>Account Status: Free</span>
      <b-button
        class="my-2 w-100 upgrade-button"
        variant="success"
        @click="onUpgrade"
      >
        Upgrade to Pro
      </b-button>
      <small>Lunch Roulette is free for up to 24 users, then starting from $29 / mo.</small>
      <div class="mt-2">
        <a
          href="https://lunchroulette.co/pricing"
          target="_blank"
        >
          <small> Learn more about pricing </small>
        </a>
      </div>
    </b-alert>
    <b-nav
      vertical
      pills
    >
      <b-nav-item
        v-for="roulette in roulettes"
        :key="roulette.id"
        active-class="active"
        :to="{ name: 'edit', params: { id: roulette.id } }"
      >
        <span
          class="dot mr-2"
          :class="{ active: roulette.active }"
        />
        {{ roulette.name }} {{ roulette.category == 'COFFEE' ? '☕️' : '🌮 ' }}
      </b-nav-item>
    </b-nav>
    <hr v-if="roulettes.length">
    <b-nav
      vertical
      pills
    >
      <b-nav-item :to="{ name: 'create' }">
        + Create New
      </b-nav-item>
    </b-nav>
    <upgrade />
  </div>
</template>

<style lang="scss" scoped>
#sidebar {
  width: 250px;
  flex: 0 0 250px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #dedede;
  border-radius: 50%;
  display: inline-block;
  &.active {
    background-color: var(--success);
  }
}
</style>
