<script>
import { mapActions, mapState } from 'vuex';
import Roulette from '@/components/roulette.vue';

export default {
  name: 'RouletteEdit',
  components: {
    Roulette,
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchRoulette(to.params.id);
    next();
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      roulette: (state) => state.roulette.roulette,
      initialSelectedDays: (state) => state.roulette.originalRoulette.days,
      users: (state) => state.user.users,
      loading: (state) => state.roulette.loading,
    }),
  },
  created() {
    this.fetchRoulette(this.id);
    this.fetchUsersForRoulette(this.id);
  },
  methods: {
    ...mapActions(['fetchRoulette', 'fetchRoulettes', 'saveRoulette', 'fetchUsersForRoulette']),
    async save(data) {
      await this.saveRoulette(data);
      await this.fetchRoulettes();
    },
  },
};
</script>

<template>
  <div>
    <roulette
      :roulette="roulette"
      :initial-selected-days="initialSelectedDays"
      @save="save"
    />
  </div>
</template>
