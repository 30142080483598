<script>
import { mapState, mapActions } from 'vuex';

import Sidebar from '@/components/sidebar.vue';

export default {
  components: {
    Sidebar,
  },
  computed: {
    ...mapState({
      roulettes: (state) => state.roulette.roulettes,
    }),
  },
  watch: {
    roulettes(newValue) {
      if (newValue && this.$route.path === '/') {
        if (this.roulettes && this.roulettes.length) {
          this.$router
            .replace({ name: 'edit', params: { id: this.roulettes[0].id } });
        }
      }
    },
  },
  created() {
    this.fetchRoulettes();
    this.fetchChannels();
  },
  methods: {
    ...mapActions([
      'fetchRoulettes',
      'fetchChannels',
    ]),
  },
};
</script>

<template>
  <div class="wrapper">
    <sidebar />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: stretch;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
</style>
