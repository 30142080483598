<script>
import { mapActions, mapState } from 'vuex';

import Roulette from '@/components/roulette.vue';

export default {
  name: 'RouletteCreate',
  components: {
    Roulette,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      roulette: (state) => state.roulette.roulette,
    }),
  },
  methods: {
    ...mapActions([
      'createRoulette',
      'fetchRoulettes',
    ]),
    async create(data) {
      await this.createRoulette(data);
      await this.fetchRoulettes();
      this.$router
        .replace({ name: 'edit', params: { id: this.roulette.id } });
    },
  },
};
</script>

<template>
  <roulette @save="create" />
</template>
